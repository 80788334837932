export enum RoutePathEnum {
  LOGIN_PATH = 'login',
  REGISTER_PATH = 'register',
  FORGOT_PASSWORD_PATH = 'forgot-password',
  EDIT_ACCOUNT = 'edit-account',
  APPOINTMENTS = 'appointments',
  MY_APPOINTMENTS = 'my-appointments',
  VIEW_APPOINTMENT = 'view-appointment',
  ALL_APPOINTMENTS = 'all-appointments',
  MY_DOCTOR_APPOINTMENTS = 'my-doctor-appointments',
  REQUESTED = 'requested',
  CONFIRMED = 'confirmed',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
  DOCTOR_APPOINTMENTS = 'doctor-appointments',
  DASHBOARD = 'dashboard',
  FRONT_DESK_DASHBOARD = 'front-desk-dashboard',
  HOME = 'home',
  USERS = 'users',
  REDIRECTING_AFTER_LOGIN = 'redirecting-after-login',
  LOGS = 'logs',
}
